import React from "react";
import "./styles.scss";

function Banner() {
  return (
    <div className="banner ">
      <div className="banner_box container s_flex">
        <h1>About</h1>
      </div>
    </div>
  );
}

export default Banner;
