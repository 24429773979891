import React from "react";
import Track from "../../component/track goods/Track";

function TrackScreen() {
  return (
    <>
      <div className="track_result">
        <Track />
      </div>
    </>
  );
}

export default TrackScreen;
